import { SHOW_CHRISTMAS_LOGO } from '@deepstream/common/constants';
import DeepStreamDarkLogo from './DeepStreamDarkLogo';
import DeepStreamXmasLogo from './DeepStreamXmasLogo';

const Logo = () => (
  <div>
    <a href={process.env.NX_LANDING_URL} style={{ display: 'block', lineHeight: 0 }}>
      {SHOW_CHRISTMAS_LOGO ? <DeepStreamXmasLogo /> : <DeepStreamDarkLogo />}
    </a>
  </div>
);

export default Logo;
