import { useTranslation } from 'react-i18next';

import AddressFields from './AddressFields';
import CompanySizeOptions from './CompanySizeOptions';
import EmptyOption from './EmptyOption';
import Field from './Field';
import Fieldset from './Fieldset';
import { useDeviceSize } from './hooks';
import * as Layout from './Layout';
import Legend from './Legend';
import UploadLogoField from './UploadLogoField';

const NameWebsiteEmailLogoFields = ({ companyId }) => {
  const { isExtraSmall, isSmall } = useDeviceSize();
  const { t } = useTranslation('onboarding');

  const name = <Field label={t('createCompany.companyName')} name="name" type="text" isRequired />;
  const website = <Field label={t('createCompany.companyWebsite')} name="web" type="text" autoCapitalize="off" isRequired />;
  const email = <Field label={t('createCompany.generalEmail')} name="email" type="email" isRequired />;
  const logo = <UploadLogoField label={t('createCompany.companyLogo')} name="logo" companyId={companyId} isRequired={false} />;

  if (isExtraSmall || isSmall) {
    return (
      <>
        {logo}
        {name}
        {website}
        {email}
      </>
    );
  } else {
    return (
      <Layout.Row>
        <Layout.Cell style={{ flex: 1, paddingRight: 20 }}>
          {name}
          {website}
          {email}
        </Layout.Cell>
        <Layout.Cell style={{ width: 180 }}>
          {logo}
        </Layout.Cell>
      </Layout.Row>
    );
  }
};

const CompanyProfileFields = ({ companyId }) => {
  const { t } = useTranslation('onboarding');

  return (
    <>
      <Fieldset>
        <Legend>{t('createCompany.companyInformation')}</Legend>
        <NameWebsiteEmailLogoFields companyId={companyId} />
        <Field label={t('createCompany.generalPhoneNumber')} name="telephone" type="tel" />
        <Field label={t('createCompany.companySize')} name="employeesNb" type="select" isRequired>
          <EmptyOption />
          <CompanySizeOptions />
        </Field>
      </Fieldset>
      <Fieldset>
        <Legend>{t('createCompany.registeredInformation')}</Legend>
        <Field label={t('createCompany.registrationNumber')} name="number" type="text" />
        <Field label={t('createCompany.dunsNumber')} name="DUNSNb" type="text" />
        <Field label={t('createCompany.registeredCompanyName')} name="registeredName" type="text" />
        <AddressFields />
      </Fieldset>
    </>
  );
};

export default CompanyProfileFields;
